.pic-ctn {
    width: 100%;
    height: 10px;
    margin-top:0;
  }
  
  @keyframes display {
    0% {
      transform: translateX(200px);
      opacity: 0;
    }
    10% {
      transform: translateX(0);
      opacity: 1;
    }
    20% {
      transform: translateX(0);
      opacity: 1;
    }
    30% {
      transform: translateX(-200px);
      opacity: 0;
    }
    100% {
      transform: translateX(-200px);
      opacity: 0;
    }
  }
  
  .pic-ctn {
    position: relative;
    width: 200px;
    height: 180px;
    margin-top: 0px;
  }
  
  .pic-ctn > img {
    position: absolute;
    top: 0;
    left: calc(50% - 100px);
    opacity: 0;
    animation: display 10s infinite;
    z-index: +1;
    
  }
  .img-algn{
    max-width: 100%;
    width: 50%;
    height: 130px;
    margin: 5% 10% 5% 22%;
    /* height: auto;
    max-width: 100%;
    margin-left: 16%;
    margin-top: 18%;
    margin-right: 10%;
    margin-bottom: 10%; */
  }
  
  img:nth-child(2) {
    animation-delay: 2s;
  }
  img:nth-child(3) {
    animation-delay: 4s;
  }
  img:nth-child(4) {
    animation-delay: 6s;
  }
  img:nth-child(5) {
    animation-delay: 8s;
  }
  .modal__backdrop {
    background: rgba(0, 0, 0, 0.45);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: -20%;
    z-index: +1;
    
  }
  
  .modal__container {
    background: #fff;
    border-radius: 5px;
    max-width: 100%;
    position: fixed;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    padding: 10px;
    width: 290px;    
    background-color: rgb(165, 217, 207);
    
  }

  .head-sec{
    margin-top: -42px;
    padding: inherit;
    margin-left: -10px;
    width: 290px;
    height: 37px;
    background: linear-gradient(rgb(2, 170, 176) 0%, rgb(0, 205, 172) 100%)
  }